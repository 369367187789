<template>
  <dashboard-layout>
    <div class="w-full p-5">
      <div class="flex flex-col">
        <div class="w-10/12 mx-auto">
          <button type="button"
            class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none"
            @click="goBack">
            <svg style="display: initial;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
            Voltar
          </button>
          <div class="py-4 flex">
            <h1 class="text-3xl">Nova Campanha</h1>
          </div>
        </div>
        <div class="w-10/12 mx-auto">
          <div class="grid grid-cols-2 gap-2 w-12/12 border border-green-500 rounded-lg py-3 px-5 mx-auto mt-5">
            <div class="flex flex-col mt-2">
              <label class="mb-2" for="">Nome da Campanha</label>
              <input v-model="$v.banners.name.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name" type="text" autocomplete="off" />
              <div v-if="$v.banners.name.$error">
                <div class="error" v-if="!$v.banners.name.required">
                  Campo obrigatório.
                </div>
              </div>
            </div>

            <div class="flex flex-col mt-1">
              <div class="mb-2">
                <label for="name">Url Link</label>
                <i class="ri-information-line ml-2" v-tooltip="{
                  content:
                    'Insira um link caso querira que ao clicar no banner ele direcione você a outra página.',
                  placement: 'right'
                }"></i>
              </div>
              <input v-model="banners.url"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name" type="text" placeholder="https://www.senarmt.com.br" />
            </div>

            <fieldset class="border hover:border-black border-green-500 rounded-lg flex col-span-2">

              <legend class="p-2"><i class="ri-upload-cloud-2-line mr-2"></i>Upload
                <i class="ri-information-line ml-1" v-tooltip="{
                  content:
                    'Adicione as imagens de banner para ser exibido na página Web ou Mobile. <br><br> Os banner inseridos no campo Web ou Mobile serão exibidos nas respectivas interfaces, não sendo obrigatório os dois estarem carregados.',
                  placement: 'right'
                }"></i>
              </legend>

              <div class="flex w-1/2 flex-col mr-5 pl-3">
                <label class="flex w-1/2 flex-row mr-5" for="name"><i class="ri-macbook-line mr-1"></i>Web</label>
                  <div class="mt-2 mb-1" v-if="prevweb">
                    <img :src="prevweb" />
                  </div>
                  <div class="flex">
                    <input
                      id="web"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 leading-tight focus:outline-none focus:bg-white"
                      type="file" @change="uploadimageweb" accept=".jpg, .jpeg, .png" />
                      <a @click="clearweb"><svg class="mt-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg></a> 
                  </div>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-300 mb-3" id="file_input_help">PNG ou JPG (MAX. 1200x275px) 2MB.</p>
              </div>

              <div class="flex w-1/2 flex-col mr-5">
                <label class="flex  flex-row mr-5" for="name"><i class="ri-device-line mr-1"></i>Mobile</label>
                  <div class="mt-2 mb-1" v-if="prevmob">
                    <img :src="prevmob" />
                  </div>
                  <div class="flex">
                    <input
                      id="mob"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 leading-tight focus:outline-none focus:bg-white"
                      type="file" @change="uploadimagemobile" accept=".jpg, .jpeg, .png" />
                    <a @click="clearmob"><svg class="mt-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/></svg></a> 

                  </div>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">PNG ou JPG (MAX. 500x200px) 2MB.</p>
              </div>

            </fieldset>

            <div class="flex col-span-2 mt-4">

              <div class="flex w-1/3 flex-col mr-5">
                <label class="mb-2" for="name">Data de Inicio
                  <i class="ri-information-line ml-1" v-tooltip="{
                    content:
                      'Defina apartir de que data a campanha será exibida.',
                    placement: 'right'
                  }"></i>
                </label>
                <input v-model="$v.banners.grant_view_at.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="name" type="date" autocomplete="off" />
                <div v-if="$v.banners.grant_view_at.$error">
                  <div class="error" v-if="!$v.banners.grant_view_at.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
              
              <div class="flex w-1/3 flex-col mr-5">
                <label class="mb-2" for="name">Data de Fim
                  <i class="ri-information-line ml-1" v-tooltip="{
                    content:
                      'Defina até que data a campanha será exibida.',
                    placement: 'right'
                  }"></i>
                </label>
                <input v-model="$v.banners.revoke_view_at.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="name" type="date" autocomplete="off" />
                <div v-if="$v.banners.revoke_view_at.$error">
                  <div class="error" v-if="!$v.banners.revoke_view_at.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>

              <div class="flex w-1/3 flex-col mr-5">
                <label class="mb-2" for="status">Status</label>
                <multiselect 
                  id="status" 
                  v-model="$v.banners.status.selected.$model" 
                  deselect-label="Remover"
                  track-by="label" 
                  label="label" 
                  select-label="Selecionar" 
                  selected-label="Selecionado"
                  :searchable="false" 
                  placeholder="Selecione um status" 
                  :options="banners.status.options">
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.label }}
                  </template>
                  <span slot="noOptions">Nenhum registro encontrado.</span>
                </multiselect>
                <div v-if="$v.banners.status.$error">
                  <div class="error" v-if="!$v.banners.status.selected.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="grid justify-items-end ">
            <button 
              type="submit"
              class="bg-green-800 text-white hover:bg-green-600 px-4 py-2 rounded-md focus:outline-none mt-2 "
              :disabled="submitting"
              @click="submit">
              {{ buttonText }}
              <i class="ri-save-line"></i>
          </button>
          </div>

        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import { required, maxLength } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateAssociate',

  title() {
    return `${process.env.VUE_APP_NAME} | Criar Associado`;
  },

  components: {
    DashboardLayout
  },


  data() {
    return {
      prevmob: null,
      prevweb: null,
      submitting: false,
      banners: {
        name: '',
        url: '',
        file: '',
        grant_view_at: '',
        revoke_view_at: '',
        status: {
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 0 }
          ],
          selected: null
        }
      }
    };
  },

  validations: {
    banners: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      grant_view_at: { required },
      revoke_view_at: { required },
      status: {
        selected: { required }
      }
    }
  },

  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authSyndicate: 'syndicate'
    }),

    buttonText() {
      return this.submitting ? 'Processando...' : 'Cadastrar';
    },
  },

  methods: {
    clearmob() {
      this.imagemobile = '';
      this.prevmob = '';
      document.getElementById('mob').value = '';
    },

    clearweb() {
      this.imageweb = '';
      this.prevweb = '';
      document.getElementById('web').value = '';
    },

    uploadimageweb(e){
      let img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      var imgex = e.target.files[0].name.split(".");
      var ext = "."+imgex.pop();
      img.onload = () => {
        if(img.width > 1200 || img.height > 275){
        this.$toast.error('A imagem carregada não atende aos requisitos de dimensões máximas 1200 X 275px.');
        document.getElementById("web").value = "";
        this.imageweb = "";
        this.prevweb = "";
        }else if(e.target.files[0].size > 2097152){
        this.$toast.error('A imagem carregada esta com o tamanho superior ao requisito de 2MB.');
        document.getElementById("web").value = "";
        this.imageweb = "";
        this.prevweb = "";
        }else{
        this.imageweb = e.target.files[0];
        this.prevweb = URL.createObjectURL(this.imageweb);
        }
      }
      if(!ext.match(/\.(gif|jpg|jpeg|tiff|png)$/i)){
        this.$toast.error('O arquivo selecionado não é uma imagem.');
        document.getElementById("web").value = "";
        this.imageweb = "";
        this.prevweb = "";
      }
    },

    uploadimagemobile(e){
      let img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      var imgex = e.target.files[0].name.split(".");
      var ext = "."+imgex.pop();
      img.onload = () => {
        if(img.width > 500 || img.height > 200){
          this.$toast.error('A imagem carregada não atende aos requisitos de dimensões máximas 500 X 200px.');
          document.getElementById("mob").value = "";
          this.imagemobile = "";
          this.prevmob = "";
        }else if(e.target.files[0].size > 2097152){
          this.$toast.error('A imagem carregada esta com o tamanho superior ao requisito de 2MB.');
          document.getElementById("mob").value = "";
          this.imagemobile = "";
          this.prevmob = "";
        }
        else{
          this.imagemobile = e.target.files[0]
          this.prevmob = URL.createObjectURL(this.imagemobile);
        }
      }
      if(!ext.match(/\.(gif|jpg|jpeg|tiff|png)$/i)){
        this.$toast.error('O arquivo selecionado não é uma imagem.');
        document.getElementById("mob").value = "";
        this.imagemobile = "";
        this.prevmob = "";
      }
    },

    submit() {

      if (this.$v.banners.$invalid) {
        this.$v.banners.name.$touch();
        this.$v.banners.grant_view_at.$touch();
        this.$v.banners.revoke_view_at.$touch();
        this.$v.banners.status.selected.$touch();
      } else {
        this.submitting = true;
        const BannersAttributes = new FormData();

        BannersAttributes.append("name", this.banners.name);
        BannersAttributes.append("url", this.banners.url);
        BannersAttributes.append('upweb', this.imageweb);
        BannersAttributes.append('upmobile', this.imagemobile);
        BannersAttributes.append("started_at", this.banners.grant_view_at);
        BannersAttributes.append("ended_at", this.banners.revoke_view_at);
        BannersAttributes.append("status", this.banners.status.selected.value);

        axios
        .post('/api/banners', BannersAttributes, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            this.$toast.success(data.data.message);
            this.$router.push({ name: 'list-ads' });
          })
          .catch(({ response }) => {
          // let errors = response.data.errors;

          //   this.$toast.warning(errors);


          Object.values(response.data.errors).forEach(error => {
            this.$toast.warning(...error);
          });
        })
        .finally(() => (this.submitting = false));
      }
    },

    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
